import * as React from 'react';
import Table from '@mui/material/Table';
import { useMediaQuery } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RarityExtractor from '../RarityExtractor';

export default function DataTable(props) {
    
    const isDesktop = useMediaQuery('(min-width:701px)');
  console.log("DataTable", props);
  // Destructure the NFT properties from props
  const NFT = props.NFTData;
  console.log("props", props.NFTData);

  // Use RarityExtractor for each attribute
  let skinScore = RarityExtractor(NFT.Skin + ' Skin');
  let backgroundScore = RarityExtractor(NFT.Background + ' Background');
  let eyesScore = RarityExtractor(NFT.Eyes + ' Eyes');
  let hairScore = RarityExtractor(NFT.Hair + ' Hair');
  let mouthScore = RarityExtractor(NFT.Mouth + ' Mouth');
  let clothingScore = RarityExtractor(NFT.Clothing + ' Clothing');
  let crownScore = RarityExtractor(NFT.Crown + ' Crown');
  let necklaceScore = RarityExtractor(NFT.Necklace + ' Necklace');
  let earringScore = RarityExtractor(NFT.Earring + ' Earring');
  let foreheadScore = RarityExtractor(NFT.Forehead + ' Forehead');
  let scepterScore = RarityExtractor(NFT.Scepter + ' Scepter');

  // Adjust createData to accept NFT attributes and their rarities
  function createData(attribute, value, NFTRarity) {
    return { attribute, value, NFTRarity };
  }

  // Create rows for each NFT attribute, including their rarity scores
  const rows = [
    createData('Skin', NFT.Skin, skinScore),
    createData('Background', NFT.Background, backgroundScore),
    createData('Eyes', NFT.Eyes, eyesScore),
    createData('Hair', NFT.Hair, hairScore),
    createData('Mouth', NFT.Mouth, mouthScore),
    createData('Clothing', NFT.Clothing, clothingScore),
    createData('Crown', NFT.Crown, crownScore),
    createData('Necklace', NFT.Necklace, necklaceScore),
    createData('Earring', NFT.Earring, earringScore),
    createData('Forehead', NFT.Forehead, foreheadScore),
    createData('Scepter', NFT.Scepter, scepterScore),
    createData('Image', NFT.imageIPFS, 'N/A'), // Assuming Image, Rarity, Rank, Multiplier, and Bonuses don't have a rarity score
    createData('Rarity', NFT.Rarity.toFixed(2), 'N/A'),
    createData('Rank', NFT.Rank, 'N/A'),
    createData('Multiplier', NFT.Multiplier, 'N/A'),
    createData('Bonuses', NFT.Bonuses.join(', '), 'N/A'),
  ];

  return (
    <TableContainer sx={{display:'flex', justifyContent:'center', padding:'0rem'}} component={Paper}>
      <Table sx={!isDesktop ? { maxWidth: 50 } : {maxWidth: 700}} size="small" aria-label="NFT attributes table">
        <TableHead >
          <TableRow >
            <TableCell align="center" sx={{ padding:'0 0rem'}}>NFT Attribute</TableCell>
            <TableCell align="center" sx={{ padding:'0 0rem'}}>Value</TableCell>
            <TableCell align="right" sx={{ padding:'0 0rem'}}>Rarity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.attribute}
              </TableCell>
              <TableCell align="center">{row.value}</TableCell>
              <TableCell align="right">{row.NFTRarity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

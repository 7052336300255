import { Box,Card, IconButton } from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './CollectionInfo.css';
const CollectionInfo = () => {

    const [collectionInfoState, setCollectionInfoState] = useState(true);

    const hideCollectionInfo = () => {
    setCollectionInfoState(false);
    }
    return(
        <div className="CollectionInfo">
{collectionInfoState ? 
<Card variant='outlined' 
sx={{color:'black',borderColor:'gold',bgColor:'black',padding:'.2rem 1.5rem',position:'relative'}}
>

<p>Queens are the OG collection in the Thrones universe.  They were minted on January 7th, 2022.</p>
<p>Contract: <a href="https://smartscout.cash/address/0x36F7e5eaFA2E96872B40cFBeD8e41767337ca8cF/transactions">0x36F7e5eaFA2E96872B40cFBeD8e41767337ca8cF</a>
<br/>Purchase: <a href="https://oasis.cash/collection/0x36F7e5eaFA2E96872B40cFBeD8e41767337ca8cF">OASIS.cash</a></p>
      
      <IconButton sx={{position:'absolute',left:'0.25rem', top:'-.5rem'}}
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={hideCollectionInfo}
        
      >
        <CloseOutlinedIcon />
      </IconButton>
</Card>
: null }
            
        </div>
    )
}
export default CollectionInfo;
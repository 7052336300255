import React, { useState } from 'react';
import Button from '@mui/material/Button';
const Rarity = (props) => {
    let TraitCount = {};
    const Data = props.data;

    const getKeyFromVal = (NFT, trait) => {
        const theKey = Object.keys(NFT).find(key => NFT[key] == trait);
        return theKey;
    };

    const calcRarity = () => {
        Data.forEach((NFT) => {
            delete NFT.imageIPFS;
            delete NFT.tokenId;

            const traitCategories = Object.keys(NFT);
            const traitsOfThisNFT = Object.values(NFT);
            let NFTOverallRarity = 0;
            const totalTraitsCount = traitsOfThisNFT.length;
            for (let i = 0; i < totalTraitsCount; i++) {
                let currentTrait = traitsOfThisNFT[i];
                let traitType = getKeyFromVal(NFT, traitsOfThisNFT[i]);

                if (!TraitCount[currentTrait]) {
                    TraitCount[currentTrait] = {};
                    TraitCount[currentTrait].type = traitType;
                    TraitCount[currentTrait].count = 1;
                    let rarityMath = TraitCount[currentTrait].count / 100;
                    TraitCount[currentTrait].rarity = `${rarityMath}%`;
                    NFTOverallRarity = NFTOverallRarity + rarityMath;
                }
                else{
                
                TraitCount[currentTrait].count += 1;
                TraitCount[currentTrait].type = traitType;
                let rarityMath = TraitCount[currentTrait].count / 100;
                TraitCount[currentTrait].rarity = `${rarityMath}%`;
                NFTOverallRarity = NFTOverallRarity + rarityMath;
            }}
          //  console.log(NFTOverallRarity);
            
           // console.log(Data);
        });

        let TraitArray = Object.entries(TraitCount);
        const TraitCountArray = Object.entries(TraitCount);
     //   console.log(TraitCountArray);
        for (let i = 0; i < TraitCountArray.length; i++) {
            //TraitCountArray[i][1].fuck = 'you';
        }
       // console.log(TraitCount);
    };

    return (
        <div>
            <button onClick={() => calcRarity()}>Run Rarity Calc</button>
        </div>
    );
};

export default Rarity;

  
   function RarityExtractor(selectedTrait, traitType) {
    const rarityJson = require('./rarity.json');
    // Iterate over each key in rarityJson

  // Directly check if the selectedTrait exists in rarityJson
  if (rarityJson[selectedTrait]) {
    return rarityJson[selectedTrait].rarity;
  } else {
    return null;
  }
}
export default RarityExtractor;
import React from 'react';
import DataTable from './DataTable';
import { Button, Box, Backdrop } from '@mui/material';
import './DataContainer.css';

const DataContainer = (props) => {
    // Control the visibility of the Backdrop with a prop or state
    const { open, setOpen } = props;

    const handleClose = (event) => {
        console.log('Backdrop clicked');
        // You might want to call setOpen(false) here if you're managing state at a higher level
        props.clickHandler();
        
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff'} }
                open={open}
                onClick={handleClose}
                
                // You can remove onClick if you don't want backdrop to close the popup when clicked
            />
            <div className="DataContainer">
                <h2 className='DataContainerHeader'>Queen #{props.NFTData.tokenID}</h2>
                <DataTable NFTData={props.NFTData} />
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:'1rem 0rem'}}>
                    <Button variant='contained' onClick={props.clickHandler}>Close</Button>
                </Box>
            </div>
        </>
    );
};

export default DataContainer;

import React, {useState} from 'react';
import '../css/QueensCards.css';
import './../css/QueensCards.css';
import Card from '@mui/material/Card';
import DataContainer from './DataContainer.js';
import NewDataContainer from './NewDataContainer';
import Button from '@mui/material/Button';
function NFTCard(props){
    let dog = 1;
  //  console.log(props);
    //console.log(props.rarity);function StatsHandler(event){
        let [StatsState, setStatsState] = useState(false);
        function StatsHandler(event){
            setStatsState(prevStatey => !prevStatey);
        }

  
//For local dev
//hellossss
//let link = `/images/${props.tokenID}.png`;
//For production
let link = `https://queens.mypinata.cloud/ipfs/${props.Image}`;

//console.log(link)
return(
 

<Card
// The transition + hover code causes a very strange error
//It puts my datacontainer inside of the card.
sx={{backgroundColor:'white'
}}
  className={`QueenCards`}
 
  >


<img src={link}></img>
<div className="idAndRarity">
<Button variant='contained' className={`Button ${props.Rank}`} onClick={StatsHandler} >#{props.tokenID}</Button>
{/*<Button variant='contained' className={`Button ${props.Rank}`} >#{props.tokenID}</Button>*/}

<p className="rarityText">Aura: {parseInt(props.Rarity)}</p>
</div>
{StatsState && <div className="overlay"></div>}

   {StatsState && <DataContainer clickHandler={StatsHandler} NFTData={props} />}
   {/*StatsState && <NewDataContainer clickHandler={StatsHandler}/>*/}
</Card>

    );
}
export default NFTCard;
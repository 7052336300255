  import React, { useState, useEffect } from "react";
  import Web3Integration from "./Web3Integration";
import { useMediaQuery } from '@mui/material';
  import './Menu.css';
  import Rarity from "./Rarity";
  import Button from '@mui/material/Button';
  import Card from '@mui/material/Card';
  import Accordion from '@mui/material/Accordion';
  import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  function Menu(props) {
    const isDesktop = useMediaQuery('(min-width:701px)');

      //let FilterToggle = false;
      let SendItFiltered = [];
      
      let DataSlice = props.DataSlice;
      let Data = props.Data;
    let FilteredData = [];
   // let [nftCounter, setNFTCounter] = useState(Data.length);

    const [expanded, setExpanded] = useState(false);
    let FieldDefault = '';
    let [FilterValues, setFilterValues] = useState({
      Clothing: '',
      Skin: '',
      Crown: '',
      Eyes: '',
      Mouth: '',
      Necklace: '',
      Scepter: '',
      Forehead: '',
      Background: '',
      Hair: '',
      Earring: '',
    Bonus:'',
  Rank:''});
    
    let [raritySort, setRaritySort] = useState(true);

 
    // this should use the filteredstate directly
    if (props.FilterToggle){
      FilteredData = props.FilteredState;
    }
    else{
      FilteredData = [...Data]
      // this should use the filteredstate directly, but the filteredstate array is strangely formattedandcant becalled the same way 
    }

    if(props.FilterToggle){
    }
    else{
      const FieldDefault = '';
    }
    
    //FilteredData = [...Data];
    
    //console.log(DataSlice[2]['Background'])
    function FilterByOwned(ownedTokens){
      console.log("We are in filtered by owned in menu.js");
    let booga = Data.filter(EachNFT => 
      ownedTokens.includes(EachNFT['tokenId'])
    );
     // console.log(booga);  
    props.SendFiltered(booga);
    }
  
    function FilterHandler(event) {
      let TraitType = event.target.name;
      let ChosenTraitName = event.target.value;
    //  props.setFilterToggle(true);
      // Update the value of the selected filter
      setFilterValues(prevValues => ({
        ...prevValues,
        [TraitType]: ChosenTraitName
      }));
  
      // Initialize an empty array for filtered data
      SendItFiltered = [];
      const numSort = (a,b)=>{
        if(raritySort){
          return b.Rarity - a.Rarity;
        }
        else{
        return a.Rarity-b.Rarity;
        }}
      if(TraitType == 'Rarity'){
       // console.log(FilteredData);
      FilteredData.sort(numSort);
      //  console.log(FilteredData);
  
        SendItFiltered = [...FilteredData];
        setRaritySort(!raritySort);
       //   console.log("trait type is rarity");
      }
      else if (TraitType === 'Bonus') {
        FilteredData.forEach(item => {
          if (item.Bonus.bonusNames.includes(ChosenTraitName)) {
            SendItFiltered.push(item);
          }
        });}
      else{

      for (let i = 0; i < FilteredData.length; i++) {
        if (FilteredData[i][TraitType] === ChosenTraitName) {
          SendItFiltered.push(FilteredData[i]);
        }
      }
    }
    //console.log(nftCounter);
    props.setNFTCounter(SendItFiltered.length);
    //console.log(nftCounter);

      props.SendFiltered(SendItFiltered);
  }
    function ResetFilter(event) {
      setRaritySort(false);
      props.setNFTCounter(Data.length);
      props.HeyResetTheFilter();
      setFilterValues({
        Clothing: '',
        Skin: '',
        Crown: '',
        Eyes: '',
        Mouth: '',
        Necklace: '',
        Scepter: '',
        Forehead: '',
        Background: '',
        Hair: '',
        Earring: '',
        Bonus:'',
        Rank:'',
      });
    }


  useEffect(() => {
    // Automatically open the accordion on desktop and close it on mobile
    setExpanded(isDesktop);
  }, [isDesktop]);

  const handleChange = () => {
    setExpanded(!expanded);
  };
    

    return (
      <Card
      sx = {{backgroundColor:'#7b46ae'}}
       className="Menu">
        
        
          
          
        <Accordion
        sx  = {{backgroundColor:'transparent', boxShadow: 'none'}}
        disableGutters={true}
        expanded={expanded}
        onChange={handleChange}
        >

        
         <AccordionSummary
          
          expandIcon={<ExpandMoreIcon
          sx ={{color:'white'}} />}
          aria-controls="panel2a-content"
          id="panel2a-header"><h2 className='FilterHeader'>Filter </h2>
          
          </AccordionSummary>
          <ul>
            <li>
            
              
              <select name="Clothing" value ={FilterValues.Clothing} onChange={FilterHandler}>
              <option value='' selected disabled hidden>Clothing</option>
                {[...new Set(FilteredData.map((Slice) => Slice.Clothing))].map(
                  (Clothing) => (
                    <option key={Clothing} value={Clothing}>{Clothing}</option>
                  )
                )}
              </select>
            </li>
            <li>
             
              <select name="Crown" value={FilterValues.Crown} onChange={FilterHandler}>
              <option value=''  disabled hidden>Crown</option>
                {[...new Set(FilteredData.map((Slice) => Slice.Crown))].map((Crown) => (
                  <option key={Crown} value={Crown}>{Crown}</option>
                ))}
              </select>
            </li>
            <li>
             
             <select name="Skin" value={FilterValues.Skin} onChange={FilterHandler}>
             <option value=''  disabled hidden>Skin</option>
               {[...new Set(FilteredData.map((Slice) => Slice.Skin))].map((Skin) => (
                 <option key={Skin} value={Skin}>{Skin}</option>
               ))}
             </select>
           </li>
            <li>
             
              <select name="Eyes" value={FilterValues.Eyes} onChange={FilterHandler}>
              <option value=''  disabled hidden>Eyes</option>
                {[...new Set(FilteredData.map((Slice) => Slice.Eyes))].map((Eyes) => (
                  <option key={Eyes} value={Eyes}>{Eyes}</option>
                ))}
              </select>
            </li>
            <li>
             
              <select name="Mouth" value={FilterValues.Mouth} onChange={FilterHandler}>
              <option value=''  disabled hidden>Mouth</option>
                {[...new Set(FilteredData.map((Slice) => Slice.Mouth))].map((Mouth) => (
                  <option key={Mouth} value={Mouth}>{Mouth}</option>
                ))}
              </select>
            </li>
            <li>
           
              <select name="Necklace" value={FilterValues.Necklace} onChange={FilterHandler}>
              <option value='' disabled hidden>Necklace</option>
                {[...new Set(FilteredData.map((Slice) => Slice.Necklace))].map((Necklace) => (
                  <option key={Necklace}>{Necklace}</option>
                ))}
              </select>
            </li>
            <li>
           
              <select name="Scepter" value={FilterValues.Scepter} onChange={FilterHandler}>
              <option value=''  disabled hidden>Scepter</option>
                {[...new Set(FilteredData.map((Slice) => Slice.Scepter))].map((Scepter) => (
                  <option key={Scepter} value={Scepter}>{Scepter}</option>
                ))}
              </select>
            </li>
            <li>
            
              <select name="Forehead" value={FilterValues.Forehead} onChange={FilterHandler}>
              <option value='' disabled hidden>Forehead</option>
                {[...new Set(FilteredData.map((Slice) => Slice.Forehead))].map((Forehead) => (
                  <option key={Forehead}>{Forehead}</option>
                ))}
              </select>
            </li>
            
            <li>
            <select name="Background" value={FilterValues.Background} onChange={FilterHandler}>
            <option value='' disabled hidden>Background</option>
              {[...new Set(FilteredData.map((Slice) => Slice.Background))].map(
                (Background) => (
                  <option key={Background} value={Background}>{Background}</option>
                )
              )}
            </select>
            </li>
            <li>
            
              <select name="Hair" value={FilterValues.Hair} onChange={FilterHandler}>
              <option value='' disabled hidden>Hair</option>
                {[...new Set(FilteredData.map((Slice) => Slice.Hair))].map((Hair) => (
                  <option key={Hair} value={Hair}>{Hair}</option>
                ))}
              </select>
            </li>
          <li>
         <select name="Earring" value={FilterValues.Earring} onChange={FilterHandler}>
         <option value='' disabled hidden>Earring</option>
           {[...new Set(FilteredData.map((Slice) => Slice.Earring))].map((Earring) => (
             <option key={Earring} value={Earring}>{Earring}</option>
           ))}
         </select>
         </li>
            <li>
            <select name="Rank" value={FilterValues.Rank} onChange={FilterHandler}>
            <option value='' disabled hidden>Rank</option>
              {[...new Set(FilteredData.map((Slice) => Slice.Rank))].map((Rank) => (
                <option key={Rank} value={Rank}>{Rank}</option>
              ))}
            </select>
            </li>
            <li>
         <select name="Bonus" value={FilterValues.Bonus} onChange={FilterHandler}>
         <option value=''  disabled hidden>Bonus</option>
           {[...new Set(FilteredData.flatMap((Slice) => Slice.Bonus.bonusNames))].map((Bonus) => (
             <option key={Bonus} value={Bonus}>{Bonus}</option>
           ))}
         </select>
         </li>
            <div className="filterButtons">
            <li>
<span className="FilterControl">
              <Button 
                            sx={{backgroundColor:'green',
              '&:hover': {
            backgroundColor: 'green',
        }}}
              variant='contained'   name="Rarity" onClick={FilterHandler}>
              Sort by Aura {!raritySort? '↑':'↓'}
              </Button>
           </span>
            </li>
            <li>
              <span className="FilterControl">
              <Button 
              sx={{backgroundColor:'green',
              '&:hover': {
            backgroundColor: 'green',width:'100%'
        }}}
              variant='contained' onClick={ResetFilter}>Reset Filter</Button>
              </span>
      
            </li>
           
  {
            <li>
            
          {/*  
            <Web3Integration 
              sendOwnedTokens={FilterByOwned}
            />*/}
            </li>
            }
            </div>

            
          </ul>
          </Accordion>
        </Card>
    
    );
  }
  export default Menu;

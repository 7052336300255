import React, { useState } from 'react';
import { Box, Divider, Drawer,  IconButton, List, ListItem, ListItemText, useMediaQuery, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './HamburgerDrawer.css';
import discordImg from '../assets/discord.svg';
import telegramImg from '../assets/telegram.svg';
import twitterImg from '../assets/twitter.svg';

function HamburgerDrawer() {
    const [isOpen, setIsOpen] = useState(false);
    const isDesktop = useMediaQuery('(min-width:701px)');

    const toggleDrawer = (open) => () => {
        setIsOpen(open);
    };

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
       
        <Box sx={{width:'100%', display:'flex',justifyContent:'center',textAlign:'center', bgcolor:'#8148b8'}}>
        
        <div className="DrawerLogoArea">
            <a href="/">
              <img className="DrawerLogo" src="/core/crown.svg" alt="Logo" />
              <span className="DrawerLogoText">THRONES</span>
            </a>
            
          </div>
        </Box>
       
        <Divider />
            <List>
            {/* add an object with name and link  */}
               
                    
                    
                    <ListItem button key='Home'>
                    
                        <ListItemText primary=
                        {<Typography sx={{fontSize:'1.2rem'}}>Home</Typography>}
                        />
                    </ListItem>
                          
                    <ListItem button key='Queens Collection'>
                    
                        <ListItemText primary=
                        {<Typography sx={{fontSize:'1.2rem'}}>Queens Collection</Typography>}
                        />
                    </ListItem>
                           
                    <ListItem button key='Kings Collection'>
                    
                        <ListItemText primary=
                        {<Typography sx={{fontSize:'1.2rem'}}>Kings Collection</Typography>}
                        />
                    </ListItem>

                    <ListItem button key='Happy Bridges Event'>
                    
                    <ListItemText primary=
                    {<Typography sx={{fontSize:'1.2rem'}}>Happy Bridges Event</Typography>}
                    />
                </ListItem>
              <Divider />
            <Box sx={{fill:'white',width:'50%',display:'flex', margin:'auto',justifyContent:'space-around', padding:'1rem 0rem'}}>
            <Box><img stroke='white' src={discordImg}></img></Box>
            <Box><img src={twitterImg}></img></Box>
            <Box><img src={telegramImg}></img></Box>
            </Box>
            </List>
        </div>
    );

    
  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor='left'
        
        open={isOpen}
        onClose={toggleDrawer(false)}
        //If Mobile, make the drawer smaller. 
        sx={{

          '& .MuiDrawer-paper': {
            bgcolor: '#8e5bc1',
            color:'white',// Set the drawer's width
            width: isDesktop? '30%': '50%',
            //color:'text.primary', // Set the paper's width to match the drawer's
            boxSizing: 'border-box'
          }
        }}
      >
        {list()}
      </Drawer>
    </>
  );
}

export default HamburgerDrawer;

import React from 'react';
import { AppBar, Button, Toolbar, Box, useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HamburgerDrawer from './HamburgerDrawer';
import '../App.css'; // Assuming you have a CSS file for your styles

function Nav() {

  const isDesktop = useMediaQuery('(min-width:701px)');


  return (
    <AppBar position="static" sx={{bgcolor: '#8e5bc1', width:'100%', margin:0}}>
      <Toolbar>
        <HamburgerDrawer
        sx={{ width: '25%', flexShrink: 0, '& .MuiDrawer-paper': { width: '25%', boxSizing: 'border-box' } }}

         />
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <div className="LogoArea">
            <a href="/">
              <img className="Logo" src="/core/crown.svg" alt="Logo" />
              <span className="Logotext">THRONES</span>
            </a>
          </div>
        </Box>
        <Box>
          <Button
          variant="contained"
          size={isDesktop ? 'large' : 'small'}
          sx={{
            flexGrow:'1',
           
          
            color:'purple'
            ,bgcolor:'white'}}>
            Connect
          </Button>
        </Box>
        {/* Additional toolbar items can be added here */}
      </Toolbar>
    </AppBar>
  );
}

export default Nav;

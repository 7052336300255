import React, { useState, useEffect } from "react";
import Data from './traitsfinal.json';
import Menu from './components/Menu.js';
import NFTCard from "./components/NFTCard.js";
import './css/QueensCards.css';
import {Helmet} from "react-helmet";
import Nav from "./components/Nav.js";
import AboveNFTs from "./components/AboveNFTs";
import CollectionInfo from "./components/CollectionInfo";
import Rarity from "./components/Rarity";
import { ethers } from "ethers";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';



function App(props) {
  const [PaginationState, setPaginationState] = useState(0);
  const [PaginationIndex, setPaginationIndex] = useState(0);
  const [CurrentView, setCurrentView] = useState(Data.slice(0, 48));
  const [FilteredState, setFilteredState] = useState(Data);
  const [FilterToggle, setFilterToggle] = useState(false);
  let [nftCounter, setNFTCounter] = useState(Data.length);
  const [raritySort, setRaritySort] = useState(false);

  function ReceiveFilter(filteredData){
    setFilterToggle(true);
    setFilteredState(filteredData);
    setCurrentView(filteredData.slice(0, 48));
    setPaginationState(0);
    setPaginationIndex(0);
  }
const InfiniteScroll = () => {
  let newPaginationState = PaginationState+48; 
  setPaginationState(prevState=>prevState+48);
setCurrentView(FilteredState.slice(0, newPaginationState+48));


}

  
  function Pagination(){
    if(FilterToggle){
      setPaginationState(prevState=>prevState+48);
      setCurrentView(FilteredState.slice(PaginationState+48, PaginationState+96));
    } else {
      //need to increment paginationstate so that pagination

      //will work for more than one click 

      //For some reason this makes it inf scroll o-o
    //  setPaginationState(PaginationState+50);
      setPaginationState(prevState=> prevState+48);
      setCurrentView(Data.slice(PaginationState+48, PaginationState+96));
    }
  }

  function ResetFilter(){
    setPaginationState(0);
    setPaginationIndex(0);
    setCurrentView(Data.slice(0, 48));
    setFilteredState(Data);
    setFilterToggle(false);
    setRaritySort(true);
  }


  useEffect(() => {
    const checkScrollBottom = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        === document.documentElement.offsetHeight
      ) {
        InfiniteScroll();
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener('scroll', checkScrollBottom);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', checkScrollBottom);
    };
  }, [InfiniteScroll, PaginationState, FilteredState]); // Depend on InfiniteScroll and any state that influences its behavior


  return (  
    <div className="Collection">
     
  <Helmet>
  <title>Queens Collection</title>
       
  <link rel="icon" href="./crown.svg" /> 
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        <meta property="og:title" content="Thrones" key="ogtitle" />
        
        <meta
          property="og:description"
          content="Thrones is an NFT universe on Smart Bitcoin Cash."
          key="ogdesc"
        />
        <meta property="og:type" content="website" key="ogtype" />
        <meta
          property="og:url"
          content="https://www.thrones.cash"
          key="ogurl"
        />
        <meta
          property="og:image"
          content="https://www.thrones.cash/images/1.png"
          key="ogimage"
        />
        <meta
          property="og:site_name"
          content="https://www.thrones.cash/"
          key="ogsitename"
        />
     
        <meta name="twitter:card" content="summary_large_image" key="twcard" />
        <meta
          property="twitter:domain"
          content="thrones.cash"
          key="twdomain"
        />
        <meta
          property="twitter:url"
          content="https://thrones.cash/"
          key="twurl"
        />
        <meta name="twitter:title" content="Thrones" key="twtitle" />
        <meta
          name="twitter:description"
          content="Thrones is an NFT universe on Smart Bitcoin Cash."
          key="twdesc"
        />
        <meta
          name="twitter:image"
          content="https://www.thrones.cash/images/1.png"
          key="twimage"
        /> 
  </Helmet>
  <Nav/>
      
      <div className="DoubleContainer">
        <div className="MenuContainer">
          <Menu Data={Data} 
          setNFTCounter={setNFTCounter}
          nftCounter={nftCounter}
          PaginateOnMenu={Pagination} 
          raritySort={raritySort}
         FilterToggle={FilterToggle} FilteredState={FilteredState} DataSlice={CurrentView} HeyResetTheFilter={ResetFilter} SendFiltered={ReceiveFilter} 
          />
          </div>
          
          <div className="GalleryContainer">
          <CollectionInfo/>
          <AboveNFTs nftCounter={nftCounter} />
            {
              CurrentView.map((NFT) => (
                <NFTCard 
                
                  key={NFT.tokenId}
                  tokenID={NFT.tokenId}
                  Background={NFT.Background}
                  Skin={NFT.Skin}
                  Eyes={NFT.Eyes}
                  Hair={NFT.Hair}
                  Mouth={NFT.Mouth}
                  Clothing={NFT.Clothing}
                  Crown={NFT.Crown}
                  Necklace={NFT.Necklace}
                  Earring={NFT.Earring}
                  Forehead={NFT.Forehead}
                  Scepter={NFT.Scepter}
                  Image={NFT.imageIPFS}
                  Rarity={NFT.Rarity}
                  Rank={NFT.Rank}
                  Multiplier={NFT.Bonus.multiplier}
                  Bonuses={NFT.Bonus.bonusNames}
                ></NFTCard>
              ))
            }
          </div>
        
      </div>
      {/*
      {PaginationIndex < (Data.length / 50) && 
        <button onClick={()=> Pagination()}>Next 50 ></button>

      }
       {PaginationIndex < (Data.length / 50) && 
        <button onClick={()=> InfiniteScroll()}>Inf></button>

      }
   */}
    
    </div>
  );
}

export default App;
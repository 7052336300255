import './AboveNFTs.css';
import { Box, Paper } from '@mui/material';
const AboveNFTs = (props) => {
    return(
        <div className="AboveNFTs">
            <p>Displaying {props.nftCounter}/10000</p>
        </div>

        
    );
};

export default AboveNFTs;